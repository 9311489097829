<template>
    <div class="pagebc" :style="'min-height:'+ (innerHeight-64) + 'px'">
        <div class="head">
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>系统设置</BreadcrumbItem>
                    <BreadcrumbItem v-if="$route.path == '/systemsetup/pagecontent/addpage'" to='/systemsetup/pagecontent'>页面内容</BreadcrumbItem>
                    <BreadcrumbItem v-if="$route.path == '/systemsetup/pagecontent/editpage'" to='/systemsetup/pagecontent'>页面内容</BreadcrumbItem>
                    <BreadcrumbItem>{{ $route.meta.title }}</BreadcrumbItem>
                </Breadcrumb>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>

export default {
    data() {
        return {
            innerHeight:0,
        }
    },
    created(){
      this.innerHeight = window.innerHeight - 2;    
        // 定义一个变量保存上一次记录的 innerHeight 值
        let lastInnerHeight = window.innerHeight;
        // 监听 resize 事件
        window.addEventListener('resize', () => {
            // 获取当前 innerHeight 值
            const currentInnerHeight = window.innerHeight;        
            // 如果 innerHeight 值发生改变，则执行相应的操作
            if (currentInnerHeight !== lastInnerHeight) {
                // 执行你想要的方法
                this.innerHeight = window.innerHeight - 2;                   
                // 更新 lastInnerHeight 变量的值
                lastInnerHeight = currentInnerHeight;
            }
        }); 
    },
    mounted(){
        this.innerHeight = window.innerHeight - 2;
    },
}
</script>

<style scoped lang="scss">
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    div{
        float: left;
        margin: 10px 0 0 10px;
    }
}
.pagebc{
    background-color: #F5F9FA;
}
</style>